<template>
<v-row>
  <v-col cols="12" md="4">
    <search-box
        label="* Setor"
        popup-label="Selecione um Setor"
        :id.sync="value.idSetor"
        :descricao.sync="value.descricaoSetor"
        :hidden-clear="true"
        :value="setor"
        :loader-fn="loadeSetor"
        item-key="id"
        item-text="descricao"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Setor encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="4">
    <search-box
        label="* Porte"
        popup-label="Selecione um Porte"
        :id.sync="value.idPorte"
        :descricao.sync="value.descricaoPorte"
        :hidden-clear="true"
        :value="porte"
        :loader-fn="loadePorte"
        item-key="id"
        item-text="descricao"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Porte encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="4">
    <search-box
        label="* Ramo de Atução"
        popup-label="Selecione um Ramo de Atução"
        :id.sync="value.idRamoAtuacao"
        :descricao.sync="value.descricaoRamoAtuacao"
        :hidden-clear="true"
        :value="ramoAtuacao"
        :loader-fn="loadeRamoAtuacao"
        item-key="id"
        item-text="descricao"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Ramo de Atução encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="4">
    <v-text-field label="* Razão Social"
                  dense
                  :rules="[
                      this.$validators.string.required,
                           v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 200)
                  ]"
                  v-model.trim="value.razaoSocial"/>
  </v-col>  
  <v-col cols="12" md="4">
    <v-text-field label="* Nome Fantasia"
                  dense
                  :rules="[
                      this.$validators.string.required,
                           v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 200)
                  ]"
                  v-model.trim="value.nomeFantasia"/>
  </v-col>
  <v-col cols="12" md="4">
    <v-text-field label="* CNPJ"
                  dense
                  placeholder="99.999.999/9999-99"
                  :rules="[
                    this.$validators.string.required,
                    this.$validators.string.cnpj
                  ]"
                  v-mask="'##.###.###/####-##'"
                  v-model.trim="value.cnpj"/>
  </v-col>
  <v-col cols="12" md="12">
    <v-textarea 
      dense
      rows="6"
      label="* Descrição da Empresa"
      :rules="[
          this.$validators.string.required,
          v => this.$validators.string.greaterThanOrEquals(v, 1),
          v => this.$validators.string.lessThanOrEquals(v, 4000),
      ]"
      v-model.trim="value.descricao"/>
  </v-col>
  <v-col cols="12">
    <strong>Contato</strong>
    <v-divider/>
  </v-col>
  <v-col cols="12" sm="4">
    <v-text-field label="Email"
                  dense
                  :rules="[
                    this.$validators.string.email,
                    v => this.$validators.string.lessThanOrEquals(v, 50),
                ]"
                v-model.trim="value.email"/>
  </v-col>
  <v-col cols="12" sm="4">
    <v-text-field label="Telefone/Celular"
                  dense
                  v-mask="'(##) #####-####'"
                  v-model.trim="value.telefone"/>
  </v-col>
  <v-col cols="12" sm="4">
    <v-text-field label="Site/Rede Social"
                  dense
                  v-model.trim="value.siteRede"/>
  </v-col>
  <v-col cols="12">
    <strong>Localização</strong>
    <v-divider/>
  </v-col>  
  <v-col cols="12" sm="3">
    <v-text-field label="* CEP"
                  dense
                  v-mask="'########'"
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 8),
                    v => this.$validators.string.lessThanOrEquals(v, 8),
                  ]"
                  v-model.trim="value.cep"/>
  </v-col>
  <v-col cols="12" sm="3">
    <v-text-field label="* Cidade"
                  dense
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                :value="value.cidade"/>
  </v-col>
  <v-col cols="12" sm="3">
    <lookup-dominio label="* Estado"
                    :hidden-clear="true"
                    :value="value.estado"
                    :type="lookups.estado"
                    :rules="[
                        this.$validators.string.required
                    ]"/>
  </v-col>    
  <v-col cols="12" sm="3">
    <v-text-field label="* Bairro"
                  dense
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.bairro"/>
  </v-col>
  <v-col cols="12" sm="6">
    <v-textarea v-if="$vuetify.breakpoint.mobile"
                dense
                rows="3"
                label="* Endereço"
                :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.endereco"/>
    <v-text-field v-else label="* Endereço"
                  dense
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.endereco"/>
  </v-col>
  <v-col cols="12" sm="6">
    <v-textarea v-if="$vuetify.breakpoint.mobile"
                dense
                rows="3"
                label="Complemento"
                :rules="[
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.complemento"/>
    <v-text-field v-else label="Complemento"
                  dense
                  :rules="[
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.complemento"/>
  </v-col>
</v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import {buscaEnderecoViaCep} from '../../../api/correios';
import Vue from 'vue';
//PKs
import {findAll as findAllSetor} from "../../../api/setor";
import {findAll as findAllPorte} from "../../../api/porte";
import {findAll as findAllRamoAtuacao} from "../../../api/ramoAtuacao";

export default {
  name: "ChatForm",
  components: {SearchBox, LookupDominio},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        estado: ItemDeDominio.COMBO_ESTADO, 
      },
      loading: false,
      error: null,
    }
  },
  computed: {
    setor(){
      if (this.value.idSetor == null) return null;
      return {
        id: this.value.idSetor,
        descricao: this.value.descricaoSetor
      }      
    },
    porte(){
      if (this.value.idPorte == null) return null;
      return {
        id: this.value.idPorte,
        descricao: this.value.descricaoPorte
      }      
    },
    ramoAtuacao(){
      if (this.value.idRamoAtuacao == null) return null;
      return {
        id: this.value.idRamoAtuacao,
        descricao: this.value.descricaoRamoAtuacao
      }      
    },
  },
  watch: {
    'value.cep': {
      handler(newValue, oldValue) {
        this.buscaEndereco();
      }
    }
  },
  methods: {
    async buscaEndereco(){
      if (this.value.cep !== "" && this.value.cep.length === 8){
        let endereco = await buscaEnderecoViaCep(this.value.cep);
        Vue.set(this.value,'cidade',endereco.localidade);
        Vue.set(this.value,'estado',endereco.uf);
        Vue.set(this.value,'bairro',endereco.bairro);
        Vue.set(this.value,'endereco',endereco.logradouro);
      }
    },
    loadeSetor(page, search) {
      return findAllSetor(page, {
        nome: search
      })
    },
    loadePorte(page, search) {
      return findAllPorte(page, {
        nome: search
      })
    },
    loadeRamoAtuacao(page, search) {
      return findAllRamoAtuacao(page, {
        nome: search
      })
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>